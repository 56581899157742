<template lang="pug">
  el-dialog( title="Utilizator" :visible.sync="showPopup" class="my-dialog-class" )
    el-form( label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
      el-row( :gutter="15" )
        el-col( :lg="8" :sm='12' :xs='12')
          el-form-item( label="Email" prop="Email"  :required="true" )
            el-input( v-model="selectedObject.Email")
        el-col( :lg="8" :sm='12' :xs='12')
          el-form-item( label="Nume" prop="Nume"  :required="true" )
            el-input( v-model="selectedObject.Nume")
        el-col( :lg="8" :sm='12' :xs='12' v-if="mode == 'add'")
          el-form-item( label="Parola" prop="Parola"  :required="true" )
            el-input( v-model="selectedObject.Parola" show-password)
        el-col( :lg="8" :sm='12' :xs='12' v-if="mode == 'add'")
          el-form-item( label="Verificare parola" prop="VerificareParola"  :required="true" )
            el-input( v-model="selectedObject.VerificareParola" show-password)
        el-col(:lg="8" :sm='12' :xs='12')
          el-form-item(label="Grup de drepturi" prop="IdGrupDrepturi")
            el-select.full-width(v-model='selectedObject.IdGrupDrepturi')
              el-option(v-for='item in rights_groups' :key="'rights-groups' + item.Id" :label='item.Name' :value='item.Id')
        el-col(:lg="8" :sm='12' :xs='12')
          el-form-item(label="Firme" prop="Firme")
            el-select.full-width(v-model='selectedObject.Firme' multiple)
              el-option(v-for='item in Firme' :key="'firme' + item.Id" :label='item.Nume' :value='item.Id')
        el-col(:lg="8" :sm='12' :xs='12')
          el-form-item(label="Departamente" prop="Departamente")
            el-select.full-width(v-model='selectedObject.Departamente' multiple)
              el-option(v-for='item in Departamente' :key="'departamente' + item.Id" :label='item.Nume' :value='item.Id')
    span( slot="footer" class="dialog-footer" )
      el-button( @click="showPopup=false" ) Renunta
      el-button( type="primary" @click="save") Salveaza
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';

export default {
  name: "utilizator-dialog",
  extends: BaseComponent,
  data () {
    var validareParola = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Scrieti parola din nou'));
      } else if (value !== this.selectedObject.Parola) {
        callback(new Error('Parolele nu se potrivesc'));
      } else {
        callback();
      }
    };
    return { 
      showPopup: false,
      mode: 'add',
      selectedObject: { Nume: "", Email: "", Parola: "", VerificareParola: "", IdGrupDrepturi: "", Firme: [], Departamente: [] },
      rules: {
        Nume: [ { required: true, message: "Numele este obligatoriu" } ],
        Email: [ { required: true, message: "Emailul este obligatoriu" } ],
        Parola: [ { required: true, message: "Parola este obligatorie" } ],
        VerificareParola: [ { required: true, validator: validareParola } ],
        IdGrupDrepturi: [ { required: true, message: "Grupul de drepturi este obligatoriu" } ]
      },
      rights_groups: [],
      Firme: [],
      Departamente: []
    }
  },
  methods: {
     show_me: async function( id ) {
         this.showPopup        = true;
        if( id == null ) 
        {
            this.mode                = "add";

            var result = await this.post("Utilizatori/get_info");
            this.rights_groups = result.GrupuriDrepturi;
            this.Firme = result.Firme;
            this.Departamente = result.Departamente;

            this.selectedObject.Nume = "";
            this.selectedObject.Email = "";
            this.selectedObject.Parola = "";
            this.selectedObject.VerificareParola = "";
            this.selectedObject.IdGrupDrepturi = "";
            this.selectedObject.Firme = [];
            this.selectedObject.Departamente = this.Departamente.map(d => d.Id);
        }
        else
        {
            this.mode             = "edit";
            var            result = await this.post("get-utilizator", { id: id } );
            this.selectedObject   = result.Item;
            this.Departamente     = result.Departamente;
        }
    },
    save: async function() {
        this.$refs['my-form'].validate( async(valid) => {
          if (valid) 
          {
            await this.post("save-utilizator", { mode: this.mode, object: this.selectedObject } );
            this.showPopup = false;
            this.$emit("save");
          }
        });
    },
    async get_info() {
      var result = await this.post("Utilizatori/get_info");
      this.rights_groups = result.GrupuriDrepturi;
      this.Firme = result.Firme;
      this.Departamente = result.Departamente;
    }
  },
  async mounted() {
    await this.get_info()
  }
};
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    
</style>