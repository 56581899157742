<template lang="pug">
  base-page
    template(v-slot:titlu)
      span Utilizatori
    template(v-slot:leftmenu)
      el-tooltip(content="Adauga un nou utilizator" v-if="$has_right('editare-utilizatori')")
        el-button(type='primary' @click='show_dialog()' icon='el-icon-plus' size='small' circle)
    el-table( :data="Results" )
      el-table-column( label="Nume" )
        template(slot-scope="prop")
          p {{prop.row.Nume}}
      el-table-column( label="Email" )
        template(#default="{row}")
          p {{row.Email}}
      el-table-column( fixed="right" label="Actiuni" width="200px" )
        template( slot-scope="scope" )
          el-tooltip( content="Modificare" v-if="$has_right('editare-utilizatori')")
            el-button( type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" )
          el-tooltip( content="Sterge" v-if="$has_right('stergere-utilizatori')")
              el-button( type="danger" size='mini' icon="el-icon-delete" circle @click="delete_item(scope.row)"    )
    utilizator-dialog( ref='dlg' @save="refresh_info()" )
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import UtilizatoriDialog from '../dialogs/UtilizatoriDialog';
import TitluPagina from '../widgets/TitluPagina';

export default {
  name: "utilizatori",
  extends: BasePage,
  components: {
    'base-page': BasePage,
    'utilizator-dialog': UtilizatoriDialog,
    'titlu-pagina': TitluPagina
  },
  data () {
    return { 
      Results: [],
      Filters: {},
    }
  },
  methods: {
    async get_info(){
      this.refresh_info();
    },
    async refresh_info(){
      var response = await this.post("list-utilizatori", { Filters: this.Filters } );
      this.Results = response.Results;
      //
      this.select_menu_item('utilizatori');

    },
    show_dialog(id){
      this.$refs.dlg.show_me(id);
    },
    async delete_item( item ){
      var confirm =  await this.$confirm(`Sterg ${item.Nume} ?`, 'Warning');
      if ( confirm ) {
         await this.post("utilizatori/delete", { id: item.Id } );
         this.refresh_info();
      }
    }
  },
  mounted(){
    settings.verify_login_and_redirect(this);
    this.get_info();
  }
};
</script>

<style lang="less" scoped>
</style>
